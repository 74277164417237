import React from "react";
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image';
import Layout from "../components/Layout/layout";
import '../components/page-styles/pages.css';
import {Image} from "../components/styles";
import Skills from "../components/skills/skills"

const IndexPage = () => {
    const data = useStaticQuery(graphql`
    query {
        contentfulHomePageIntro {
            title
            description {
              description
            }
            image {
              fluid {
                base64
                tracedSVG
                srcWebp
                srcSetWebp
                srcSet
                src
                sizes
              }
            }
          }   
    }
    `)
    const {title, description, image} = data.contentfulHomePageIntro
    return (
    <Layout>
        <section className="inner-wrapper">
          <figure className="image-container">
            <Img style={Image} fluid={image.fluid} alt={`Net Knight logo`} />
          </figure>
          <figcaption className="figCaption">
            <h1 className="title">{title}</h1>
            <p className="paragraph">{description.description}</p>
            <Skills/>
          </figcaption>
        </section>
    </Layout> 
)}

export default IndexPage;