import React from 'react'
import "./skills.css"
import bootstrap from "../../images/bootstrap.svg"
import react from "../../images/react.svg"
import contentful from "../../images/contentful.svg"
import css from "../../images/css3.svg"
import html from "../../images/html5.svg"
import javascript from "../../images/javascript.svg"
import gatsby from "../../images/gatsby.svg"

export default function skills() {
    return (
        <article className="skill-section">
            <h2 className="medium-title">SKILLS</h2>
            <div className="skills-container">
              <img className="skill-img" src={html} alt="html-logo" />
              <img className="skill-img" src={css} alt="css3-logo" />
              <img className="skill-img" src={javascript} alt="javascript-logo"/>
              <img className="skill-img" src={react} alt="react-logo" />
              <img className="skill-img" src={gatsby} alt="gatsby-logo"/>
              <img className="skill-img" src={contentful} alt="contentful-logo"/>
              <img className="skill-img" src={bootstrap} alt="bootstrap-logo"/>
            </div>
          </article>
    )
}
